@import './../../../utils/mixin.scss';
@import './../../../utils/variables.scss';

.exprnc {
  height: 650px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  align-items: center;


  @include onTablet {

    margin-top: 50px;
  }

  @include onPhoneMax {
    margin-top: 350px;
    gap: 66px;
    height: 1100px;
  }

  @include onPhoneMin {
    margin-top: 450px;
  
  }

  &__title {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 36px;
    color: $text-color;

    @include onPhoneMax {
      font-size: 24px;
      line-height: 36px;
    }
  
    @include onPhoneMin {
      font-size: 18px;
      line-height: 36px;
    }
  }

  &__subTitle{
    @include lightFont();
    font-size: 16px;
  }

  &__table{
    @include lightFont();
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: left;
    background-color: rgb(241, 252, 253);
    color: rgb(94, 94, 94);
    padding: 10px;
    border: 1px solid gray;
    border-radius: 10px;
  }

  &__price{
    @include mainFont();
    font-weight: bold;
    font-size: 20px;
  }

  &__priceBlock{
    @include lightFont();
    font-weight: bold;
    text-align: left;
    width: 200px;
    display: flex;
    flex-direction: column;

    &--item{
      @include lightFont();
      font-weight: bold;
    }
  }
}