@import './../../../utils/mixin.scss';
@import './../../../utils/variables.scss';

.navigation {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  @include mainFont();

  &__logo {
    display: flex;
    gap: 1px;
    align-items: center;
    height: 25px;
    line-height: 25px;

    &--text {
      color: white;
      font-size: 24px;
      line-height: 24px;
      padding-left: 10px;
      
    }

    &--img{
      height: 80px;
      display: block;
    }

    &--lang {
      font-size: 15px;
      line-height: 18px;
      color: white;
      cursor: pointer;
      letter-spacing: 1px;
     

    }

    &--langColor {
      color: white;
      font-size: 18px;
      font-weight: bold;
    }
  }

  &__nav {
    display: flex;
    gap: 30px;

    @include onNotebook {
      display: none;
    }
  }

  &__link {
    text-decoration: none;
    color: white;
    font-size: 18px;
    @include mainFont();
  }

  &__button {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 20px;
    font-size: 18px;
    color: white;
    cursor: pointer;

    @include onNotebook {
      display: none;
    }

    &--enter{
      color: white;
      text-decoration: none;
    }
    &--reg {
      background-color: $button-color;
      height: 50px;
      width: 147px;
      border-radius: 10px;
      line-height: 50px;
      font-size: 15px;
      word-spacing: 3px;
      font-weight: bold;
      @include mainFont();
      color: white;
      text-decoration: none;

    }
  }

  &__menu {
    height: 30px;
    width: 43px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    cursor: pointer;
    display: none;

    @include onNotebook {
      display: flex;
    }

    &--line{      
      height: 2.86px;
      border-radius: 2.86px;
      background-color: white;
      position: relative;
    }

    &--middleLine {
      width: 32.86px;
      left: 2.86px;
    }

    &--topLine {
      width: 17.14px;
      left: 18.57px;
      text-align: left;
    }

    &--bottomLine {
      width: 15.71px;
      left: 2.86px;
    }
  }
}