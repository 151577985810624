@import './../../../utils/mixin.scss';
@import './../../../utils/variables.scss';

.arrBlocks{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 64px;
  gap: 20px;

  &__title{
    @include mainFont();
    font-size: 46px;
    font-weight: bold;
    text-align: center;
  }

  &__subTitle{
    @include lightFont();
    font-size: 18px;
  }


  &__background{
    background-image: url(./../../../images/mainVector.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 234px;

    @include onNotebook{
      height: 411px;
    }

    @include onPhoneMax{
      height: 287px;
    }
  }
}