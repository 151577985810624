@import './../../utils/mixin.scss';
@import './../../utils/variables.scss';

.header{
  background-image: url(../../images/Vector.svg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 740px;
  margin-bottom: 25px;

  @include onNotebook {
    background-image: url(../../images/Vector960.svg);
    height: 584px;
  }

  @include onTablet {
    background-image: url(../../images/Vector768.svg);
  }

  @include onPhoneMax {
    background-image: url(../../images/Vector480.svg);
  }

  @include onPhoneMin {
    background-image: url(../../images/Vector360.svg);
  }

  &__block{
    display: flex;
    flex-direction: column;
    gap: 24px;

    height: 450px;

    @include onNotebook {

      height: 320px;
    }
    @include onTablet {

     }

    @include onPhoneMax {
      width: 40px;

    }
    @include onPhoneMin {

      height: 248px;
    }


  }
  &__flex{
    display: flex;
    gap: 30px;

    @include onPhoneMax {
      flex-direction: column;
      gap: 10px;
    }
  }

  &__title{

    @include boldFont();
    font-weight: 800;
    color: white;
    font-size: 48px;
    display: inline-block;
    width: 760px;

    &--underline{
      text-decoration: underline;
    }
    @include onNotebook {
     font-size: 36px;
    }


    @include onPhoneMax {
      font-size: 24px;
      width: 300px;
    }
    @include onPhoneMin {
      font-size: 16px;
      width: 300px;
    }
  }

  &__subTitle{

    @include lightFont();
    color: white;
    font-size: 36px;
    width: 590px;
    display: inline-block;

    @include onNotebook {
      font-size: 24px;

     }

     @include onPhoneMax {
       font-size: 16px;
       width: 300px;

     }
     @include onPhoneMin {
       font-size: 14px;
  
     }
  }

  &__button{
    text-transform: uppercase;
    @include mainFont();
    border: none;
    color: white;
    font-size: 13px;
    background-color: $button-color;
    width: 176px;
    border-radius: 10px;
    cursor: pointer;
    letter-spacing: 1px;
    padding: 18px 0;

  
  
  }

  &__buttonDemo{
    text-transform: uppercase;
    @include mainFont();
    border: 1px solid $button-color;
    color: $button-color;
    font-size: 14px;
    letter-spacing: 1px;
    background-color: white;
    width: 176px;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    padding: 18px 0;

   
  }

  &__container{
    position: relative;
    height: 650px;
  }

  &__photo{
    position: absolute;
    right: 0;
    bottom: 0;
    height: 600px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.9);

    @include onNotebook {
      height: 455px;
      bottom: 170px;
     }

     @include onTablet {
      height: 324px;
      bottom: 160px;
     }
 
     @include onPhoneMax {
      height: 280px;
      bottom: 230px;
     }

     @include onPhoneMin {
      height: 204px;

     }
     
 
  }
}

.silka{
  text-decoration: none;
  color: $button-color;
  height: 50px;
}
.silkaWhite{
  text-decoration: none;
  color: white;
  height: 50px;

}
